export const almha = {
    title: 'Mind | Almha',
    meta: [
        { name: 'description', content: '' },
    ],
    slug: 'almha',
    banner: {
        image: require('@images/portfolio/almha/banner.jpg'),
        name: 'Almha',
        tag: 'Business Design > <strong>Almha</strong>'
    },
    objetivos: [
        {
            title: 'Objetivo',
            description: 'Business Design',
        },
        {
            title: 'Desafio',
            description: 'Mapear oportunidades de negócios no setor imobiliário para a criação de um novo serviço.',
        },
        {
            title: 'Estratégia',
            description: 'Realizamos um Desk Research de aprofundamento no mercado imobiliário, implementamos pesquisas qualitativas com potenciais usuários e elaboramos o Blueprint para mapear interações do público no novo negócio.',
        },
        {
            title: 'Solução',
            description: 'Desenvolvemos um Report Contextual e de Cenários Futuros, o desenho do novo Modelo de Negócio e o Design de Serviços, com o mapeamento da Jornada de Usuários e Stakeholders.',
        },
    ],
    image: null,
    book: [
        require('@images/portfolio/almha/book_01.jpg'),
        require('@images/portfolio/almha/book_02.jpg'),
        require('@images/portfolio/almha/book_03.jpg'),
        require('@images/portfolio/almha/book_04.jpg'),
        require('@images/portfolio/almha/book_05.jpg'),
    ]
}
