<template lang="pug">
    section#banner
        .img
            img(:src="contents.image")
        p(v-html="contents.tag")
        ul.icons
            li
                SvgIcon(data="@svgs/icon_01.svg", original)._svg01
            li
                SvgIcon(data="@svgs/icon_02.svg", original)._svg02
            li
                SvgIcon(data="@svgs/icon_03.svg", original)._svg03
        h2 {{contents.name}}
        SvgIcon(data="@svgs/m.svg", original).m
</template>

<script>
export default {
    name: "section-banner",
    props: {
        contents: {
            type: [ Object, Array, String ],
            required: true
        },
    },
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
