<template lang="pug">
    section#image
        img(:src="contents")
</template>

<script>
export default {
    name: "section-image",
    props: {
        contents: {
            type: [ Object, Array, String ],
            required: true
        },
    },
}
</script>

<style lang="stylus" scoped src="./Image.styl"></style>