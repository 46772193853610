<template lang="pug">
	main#case
		Banner(:contents="empresa.banner")
		Objetivos(:contents="empresa.objetivos")
		ImageDivider(:contents="empresa.image" v-if="empresa.image")
		Book(:contents="empresa.book")
		Formulario(formTitle="Vamos marcar um café e revolucionar mentes?" subTitle="Está na hora do seu negócio ir além!")
</template>

<script>
import { getCase } from './data'

import Banner from '@sections/Case/Banner/Banner'
import Objetivos from '@sections/Case/Objetivos/Objetivos'
import ImageDivider from '@sections/Case/Image/Image'
import Book from '@sections/Case/Book/Book'
import Formulario from '@components/Formulario/Formulario'

export default {
	name: "view-case",
	metaInfo() {
		return {
			title: this.empresa.title,
			meta: this.empresa.meta,
		}
	},
	components: {
		Banner,
		Objetivos,
		ImageDivider,
		Book,
        Formulario,
	},
	computed: {
		empresa () {
			return getCase(this.$route.params.slug)
		}
	}
}
</script>

<style lang="stylus" scoped src="./Case.styl"></style>
