export const faculdadeInspirar = {
    title: 'Mind | Faculade Inspirar',
    meta: [
        { name: 'description', content: '' },
    ],
    slug: 'faculdade-inspirar',
    banner: {
        image: require('@images/portfolio/inspirar/banner.jpg'),
        name: 'Inspirar',
        tag: 'Business Design > <strong>Inspirar</strong>'
    },
    objetivos: [
        {
            title: 'Objetivo',
            description: 'Estratégia e Gestão de Marca/Branding',
        },
        {
            title: 'Desafio',
            description: 'Rever e alinhar a missão, visão e os valores da empresa, além de traduzir e desenhar o DNA, Persona e tom de voz, e propor um novo design da identidade visual e verbal.',
        },
        {
            title: 'Estratégia',
            description: 'Realização de pesquisas qualitativas e quantitativas com os stakeholders envolvidos e aplicação de workshops com o time estratégico',
        },
        {
            title: 'Solução',
            description: 'Relatório de diagnóstico das pesquisas + Diagnóstico sobre percepção interna da personalidade da marca para construção da nova identidade visual alinhada aos propósitos da empresa e suas ambições futuras.',
        },
    ],
    image: require('@images/portfolio/inspirar/image.jpg'),
    book: [
        require('@images/portfolio/inspirar/book_01.jpg'),
        require('@images/portfolio/inspirar/book_02.jpg'),
        require('@images/portfolio/inspirar/book_03.jpg'),
        require('@images/portfolio/inspirar/book_04.jpg'),
        require('@images/portfolio/inspirar/book_05.jpg'),
        require('@images/portfolio/inspirar/book_06.jpg'),
    ]
}
