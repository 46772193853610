<template lang="pug">
    section#objetivos
        .wrapper
            ul
                li(v-for="objetivo in contents")
                    .title
                        h3 {{objetivo.title}}
                    .description
                        p {{objetivo.description}}
</template>

<script>
import { isMobile } from '@lib/isMobile.js'

export default {
    name: "section-objetivos",
    props: {
        contents: {
            type: [ Object, Array, String ],
            required: true
        },
    },
    data() {
        return {
            scenes: [],
        }
    },
    mounted() {
        this.initScenes()
        setTimeout(() => {
            this.$scrollmagic.addScene(this.scenes)
        }, 500)
    },
    methods: {
        initScenes() {
            if(!isMobile())
                this.scenesObjetivos()
            else
                this.scenesObjetivosMobile()
        },
        scenesObjetivos() {
            //First
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#case #objetivos',
				triggerHook: 0.5,
                reverse: false
			}).setTween("#case #objetivos ul", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

		},
        scenesObjetivosMobile() {
            //First
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#case #objetivos',
				triggerHook: 0.6,
                reverse: false
			}).setTween("#case #objetivos ul", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

		},
    }
}
</script>

<style lang="stylus" scoped src="./Objetivos.styl"></style>