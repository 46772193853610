<template lang="pug">
    section#book
        ul
            li(v-for="book, index in contents" ref="li")
                img(:src="book")
</template>

<script>
import { isMobile } from '@lib/isMobile.js'

export default {
    name: "section-book",
    props: {
        contents: {
            type: [ Object, Array, String ],
            required: true
        },
    },
    data() {
        return {
            scenes: [],
        }
    },
    mounted() {
        this.initScenes()
        setTimeout(() => {
            this.$scrollmagic.addScene(this.scenes)
        }, 500)
    },
    methods: {
        initScenes() {
            if(!isMobile())
                this.scenesBook()
            else
                this.scenesBookMobile()
        },
        scenesBook() {
            //First
            this.$refs.li.forEach((item, index) => {
                this.scenes.push(this.$scrollmagic.scene({
                    triggerElement: `#case #book li:nth-child(${index+1})`,
                    triggerHook: 0.3,
                    reverse: false
                }).setTween(`#case #book li:nth-child(${index+1})`, {
                    css: {
                        opacity: '1',
                    }
                }))
            });
		},
        scenesBookMobile() {
            //First
            this.$refs.li.forEach((item, index) => {
                this.scenes.push(this.$scrollmagic.scene({
                    triggerElement: `#case #book li:nth-child(${index+1})`,
                    triggerHook: 0.6,
                    reverse: false
                }).setTween(`#case #book li:nth-child(${index+1})`, {
                    css: {
                        opacity: '1',
                    }
                }))
            });
		},
    }
}
</script>

<style lang="stylus" scoped src="./Book.styl"></style>